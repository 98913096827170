.client_wraper2 {
  position: fixed;
  z-index: 99999;
  left: 100%;
  right: 0;
  bottom: 31%;
  top: 0;
  transform: translate(50%, 50%);
}

.client_service_list2 {
  list-style: none;
  display: flex;
  flex-direction: column;
  background: #2d86de;
  width: 6rem;
  height: 80%;
  color: #fff;
  font-weight: 700;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  overflow-y: auto;
  scrollbar-width: none;
}

ul.client_service_list2 li {
  padding: 0.5rem;
  text-align: center;
  cursor: pointer;
}

.client_service_list2 li:hover {
  background-color: #0ea158;
}

.service_wraper {
  width: 7rem;
  height: 80vh;
  overflow: hidden;
}

.settings_wraper2 {
  position: relative;
  transform: translate(-6rem);
  width: 7rem;
  height: 80%;
}

.show-menu2 {
  transform: translate(-6rem);
  transition: 0.3s;
}

.hide-menu2 {
  transform: translate(0rem);
  transition: 0.3s;
}

@-webkit-keyframes rotating

/* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.client_setting_icon2 {
  position: absolute;
  font-size: 1.4rem;
  color: rgb(244, 244, 255);
  top: 38px;
  left: 2px;
  /* right: 20px; */
  /* left: 33px; */
  cursor: pointer;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  animation: rotating 6s linear infinite;
  -webkit-animation: rotating 6s linear infinite;
  -moz-animation: rotating 6s linear infinite;
  -ms-animation: rotating 6s linear infinite;
  -o-animation: rotating 6s linear infinite;
}

.setting_icon_wraper2 {
  width: 70px;
  height: 70px;
  background-color: #2d86de;
  position: absolute;
  top: 50%;
  right: 60px;
  transform: rotate(44deg);
  border-bottom-left-radius: 9px;
  z-index: -100;
}

.menu_icon2 {
  color: #000;
}

.menu_label2 {
  font-size: 11.5px;
  padding: 0rem 0.2rem;
}

/* @media screen and (max-width: 2100px) {
  
} */

.profile_header {
  height: 2.3rem;
  width: 100%;
  padding: 1rem;
  margin: 1.3rem 0px 0px 0px;
  display: flex;
  justify-content: end;
  align-items: center;
}

.profileDelete {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  color: #000000;
  font-size: 1.1rem;
  cursor: pointer;
  margin-right: 2rem;
}

.monthlyBill {
  font-size: 1.2rem;
  padding: 0.4rem 0.3rem;
  margin: 0rem 0rem 0.6rem 0rem;
  font-weight: 600;
  background-color: #1276a705;
  border-radius: 0.2rem;
}

.clintTitle {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  background-color: #b1b1b11b;
}

.clintPotos {
  width: 5rem;
  height: 5rem;
  object-fit: cover;
  border-radius: 50%;
  margin: 0.5rem 1rem;
}

.clintName {
  font-family: Verdana, sans-serif;
  font-size: xx-large;
  font-weight: 100;
}

.bandwithRxTx {
  height: 1.3rem;
  width: 3rem;
  font-weight: 600;
  text-align: center;
  color: #fff;
  font-size: 1rem;
}

.bandwithKbps {
  font-weight: 500;
  font-family: "Times New Roman", Times, serif;
}

.profileInfo {
  margin: 0.8rem 1rem;
  font-weight: 600;
}

.profileSetting {
  list-style: none;
  display: flex;
  align-items: center;
  padding: 10px 38px;
  font-weight: 600;
  cursor: pointer;
  transition: 0.3s;
}

.profileSetting:hover {
  filter: contrast(5) !important;
  background-color: #1276a705;
}

#activeSetting {
  box-shadow: 6px 0px 0px #297fd4 inset;
  filter: contrast(5);
  color: #297fd4;
}

.displayGridManual6_4 {
  display: grid;
  grid-template-columns: minmax(39%, auto) minmax(60%, auto);
  grid-gap: 1%;
  margin: 10px 0;
}

.report5_5,
.displayGridHorizontalFill5_5 {
  display: grid;
  grid-template-columns: minmax(50%, auto) minmax(50%, auto);
}

.displayGridVertical5_5 {
  display: grid;
  grid-template-rows: minmax(48%, auto) minmax(48%, auto);
  grid-gap: 4%;
}

.profile_details {
  height: 21.3rem;
}

.setting_details {
  height: 100vh;
}

.clintSetting {
  border: none !important;
}

.manualLable {
  display: flex;
  justify-content: end;
  color: rgb(131, 131, 131);
}

.forgetPassword {
  display: flex;
  justify-content: start;
  color: #2e87e0;
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  margin-top: 2px;
}

.forgetPassword:hover {
  text-decoration: underline;
}

.profileSidebar {
  background-color: aqua;
}

.profileOptions {
  font-size: 18px;
  margin-right: 15px;
  margin-top: -1px;
  padding: 0 0.5rem;
  color: #5e5d5d !important;
}

.options_name {
  display: flex;
  align-self: flex-end;
  font-size: 18px;
  font-weight: 600;
}

.defaultProfile {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0px;
}

.profileAgree {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.1rem;
  font-weight: 500;
  gap: 1rem 0px;
  margin-bottom: 1.5rem;
  color: rgb(187, 187, 187);
}

.submitButton {
  display: flex;
  align-items: center;
  column-gap: 8px;
  font-size: 1.1rem;
}

.profileDetails {
  font-size: 1rem;
  padding: 0.1rem;
  margin-bottom: 0.2rem;
  font-weight: 600;
}

.reportHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reportAccordion.accordion-button::after {
  width: 0px !important;
}

.reportAccordion.accordion-button:not(.collapsed) {
  background-color: #fff !important;
}

.reportAction {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 0.6rem;
}

.reportName {
  font-size: 18px;
  font-weight: 600;
}

.reportCollect {
  padding: 0.5rem 0.5rem;
  font-size: 16px;
  font-weight: 600;
  background-color: #b1b1b11b;
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
}

.collectReport {
  padding: 0.5rem 0.5rem;
  font-size: 15px;
  font-weight: 500;
}

.reportOptions > p {
  display: grid;
  grid-template-columns: minmax(50%, auto) minmax(50%, auto);
}

.smsLength_smsType {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.smsLengthCount {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
  font-weight: bold;
}

.smsType {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 515px) {
  #delete_profile {
    display: none;
  }

  #activeSetting {
    box-shadow: 0px 0px 6px #297fd4 inset;
  }

  #clintSetting {
    display: flex;
  }

  #profile_setting {
    height: 4.3rem !important;
  }

  .manualLable {
    justify-content: start;
  }

  .vr_line {
    display: none !important;
  }

  .clintPotos {
    width: 3rem;
    height: 3rem;
    margin-right: 0.5rem;
  }

  .clintName {
    font-family: Verdana, sans-serif;
    font-size: 1.2rem;
    font-weight: 300;
  }

  .profileDelete {
    display: flex;
    align-items: center;
    font-size: 1rem;
    margin-right: 5px !important;
  }

  .close_Btn {
    font-size: 1rem;
  }

  .displayGridManual6_4 {
    display: block;
  }

  .report5_5 {
    display: block;
  }

  .options_name {
    display: none;
  }

  .clint_profile_setting {
    display: none !important;
  }

  .profileSetting {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    justify-content: center;
    padding: 5px 0.6rem;
  }

  .profileOptions {
    margin-right: 0px;
  }

  .setting_details {
    height: fit-content;
  }

  .client_details {
    height: 15rem !important;
  }

  .smsLengthCount {
    display: block;
  }

  .smsType,
  .smsLength_smsType {
    display: block;
    justify-content: start;
    grid-gap: 10px;
  }

  .details_setting {
    margin-bottom: 1rem;
  }
}

@media only screen and (min-width: 516px) and (max-width: 767px) {
  #delete_profile {
    display: none;
  }

  #activeSetting {
    box-shadow: 0px 0px 6px #297fd4 inset;
  }

  #clintSetting {
    display: flex;
  }

  #profile_setting {
    height: 4.3rem;
  }

  .manualLable {
    justify-content: start;
  }

  .vr_line {
    display: none !important;
  }

  .clintPotos {
    width: 3.3rem;
    height: 3.3rem;
  }

  .clintName {
    font-family: Verdana, sans-serif;
    font-size: larger;
    font-weight: 100;
  }

  .profileDelete {
    display: flex;
    align-items: center;
    font-size: inherit;
    margin-right: 5px !important;
  }

  .close_Btn {
    font-size: 1.1rem;
  }

  .displayGridManual6_4 {
    display: block;
  }

  .options_name {
    display: none;
  }

  .clint_profile_setting {
    display: none !important;
  }

  .profileSetting {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    justify-content: center;
    padding: 5px 1.3rem;
  }

  .profileOptions {
    margin-right: 0px;
  }

  .setting_details {
    height: fit-content;
  }

  .smsLengthCount {
    display: block;
  }

  .smsType,
  .smsLength_smsType {
    display: block;
    justify-content: start;
    grid-gap: 10px;
  }

  .details_setting {
    margin-bottom: 1rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  #delete_profile {
    display: none;
  }

  #activeSetting {
    box-shadow: 0px 0px 6px #297fd4 inset;
  }

  #clintSetting {
    display: flex;
  }

  #profile_setting {
    height: 4.3rem;
  }

  .manualLable {
    justify-content: start;
  }

  .vr_line {
    display: none !important;
  }

  .clintPotos {
    width: 3.8rem;
    height: 3.8rem;
    object-fit: cover;
    border-radius: 50%;
    margin: 0.5rem 1rem;
  }

  .clintName {
    font-family: Verdana, sans-serif;
    font-size: larger;
    font-weight: 100;
  }

  .profileDelete {
    display: flex;
    align-items: center;
    font-size: inherit;
    margin-right: 5px !important;
  }

  .close_Btn {
    font-size: 1.1rem;
  }

  .displayGridManual6_4 {
    display: block;
  }

  .options_name {
    display: none;
  }

  .clint_profile_setting {
    display: none !important;
  }

  .profileSetting {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    justify-content: center;
    padding: 5px 1.5rem;
  }

  .profileOptions {
    margin-right: 0px;
  }

  .setting_details {
    height: fit-content;
  }

  .smsLengthCount {
    display: block;
  }

  .smsType,
  .smsLength_smsType {
    display: block;
    justify-content: start;
    grid-gap: 10px;
  }

  .details_setting {
    margin-bottom: 1rem;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .vr_line {
    display: none !important;
  }

  .smsLengthCount {
    display: block;
  }

  .smsType,
  .smsLength_smsType {
    display: block;
    justify-content: start;
    grid-gap: 10px;
  }
}
