.client_section {
  width: 100%;
  display: flex;
  background-image: linear-gradient(to right, #142a48, #273a60);
  color: #fff;
  min-height: 100vh;
}

.card-body.client_service_card {
  padding: 1.5rem;
  text-align: center;
  background-image: linear-gradient(to right, #00c6ff, #0072ff);
}

.card-body.client_service_card {
  color: #fff;
}

.card_wraper {
  display: flex;
  align-items: center;
  justify-content: center;
}

ul.client_service_list {
  list-style: none;
  background: #1b2430;
  width: 6rem;
  margin-top: 5rem;
  color: #fff;
  font-weight: 700;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

ul.client_service_list li {
  padding: 0.5rem;
  text-align: center;
  cursor: pointer;
}

ul.client_service_list li:hover {
  background-color: #000;
}

.settings_wraper {
  position: relative;
  transform: translate(-7rem);
  width: 7rem;
}

.show-menu {
  transform: translate(0rem);
  transition: 0.3s;
}

.hide-menu {
  transform: translate(-7rem);
  transition: 0.3s;
}

@-webkit-keyframes rotating

/* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.client_setting_icon {
  position: absolute;
  font-size: 2rem;
  color: #3eff00;
  top: -5%;
  left: 33px;
  cursor: pointer;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  animation: rotating 6s linear infinite;
  -webkit-animation: rotating 6s linear infinite;
  -moz-animation: rotating 6s linear infinite;
  -ms-animation: rotating 6s linear infinite;
  -o-animation: rotating 6s linear infinite;
}

.setting_icon_wraper {
  width: 70px;
  height: 70px;
  background-color: #1b2430;
  position: absolute;
  top: 42%;
  left: 60px;
  transform: rotate(44deg);
  border-top-right-radius: 9px;
  z-index: -100;
}

.customer_information {
  padding: 2.5rem, 2.5rem, 0, 0;
}

.customer_information_table {
  display: flex;
  justify-content: space-between;
}

.menu_icon {
  font-size: 1.5rem;
  color: #3eff00;
}

.client_profile {
  width: 100%;
  margin: 0 auto;
}

table.client_info_table {
  color: #000;
  font-weight: 700;
}

table.client_info_table td {
  padding: 0px 10px !important;
  color: #fff !important;
  font-size: 1.1rem !important;
}

.client_dashboard_section {
  padding: 1rem;
  margin: auto;
}

span.navHeaderCompany {
  color: #fff;
  font-weight: 700;
  font-family: cursive;
  font-size: 1.5rem;
}

.up_downLoad {
  display: flex;
  width: 100%;
  margin-top: 2rem;
  justify-content: center;
}

.up_down {
  margin-right: 20px;
  background-color: #1b2430;
  color: #3eff00;
  padding: 2rem;
  width: 24rem;
}

/* reset password  */

.password_reset_form {
  color: #fff !important;
  margin-top: 3rem;
}

.password_reset_form label {
  color: #fff;
}

.packages_info_wraper {
  width: fit-content;
  padding: 10px;
  background-color: #1b2430;
  display: flex;
  align-items: center;
}

.packages_info_wraper p {
  margin-right: 0.5rem;
}

.packages_info_wraper p {
  font-weight: 600;
}

.package_table {
  width: 60% !important;
}

.packageList ul li:hover {
  padding: 0.6rem;
  transform: scale(1.1);
}

.package_list_card {
  transition: transform 0.4s;
}

.restForm-wraper {
  width: 80%;
  margin: 0 auto;
}

/* .package_list_card:hover {
  transform: scale(1.2);
} */

.package_select_button {
  cursor: pointer;
}

.white-bg {
  background-color: #ffffff;
}

.page-heading {
  border-top: 0;
  padding: 0 10px 20px 10px;
}

.forum-post-container .media {
  margin: 10px 10px 10px 10px;
  padding: 20px 10px 20px 10px;
  border-bottom: 1px solid #f1f1f1;
}

.forum-avatar {
  float: left;
  margin-right: 20px;
  text-align: center;
  width: 110px;
}

.mid-icon {
  font-size: 66px;
}

.forum-item {
  background-color: #1b2430;
  margin: 10px 0;
  padding: 10px 0 20px;
}

.forum-item small {
  color: #3eff00;
}

.forum-item .forum-sub-title {
  color: #fff;
  margin-left: 50px;
  margin-top: 5px;
  font-weight: 700;
}

.forum-title {
  margin: 15px 0 15px 0;
}

.forum-info {
  text-align: center;
  margin: 0px 5px;
}

.forum-icon {
  float: left;
  width: 30px;
  margin-right: 20px;
  text-align: center;
}

.forum-item-title {
  color: inherit;
  display: block;
  font-size: 18px;
  font-weight: 600;
}

.forum-item-title:hover {
  color: inherit;
}

.forum-item-title {
  color: #1ab394;
}

.ibox-content {
  color: inherit;
}

.mid-icon {
  font-size: 66px !important;
}

.payment_history_list {
  display: flex;
  justify-content: space-between;
  margin-right: 1rem;
}

.view-payment {
  cursor: pointer;
}

.view-payment button {
  width: 80px;
  border-radius: 10px;
  background-color: #73a3ce;
  border: none;
  color: #fff;
}

.view-payment button:hover {
  color: #000;
  background-color: #ddd;
}

.support-message-form {
  width: 90%;
  display: flex;
  justify-content: center;
}

.support-message-form input.form-control {
  color: #fff !important;
}

.support-ticket-table {
  width: 90%;
  margin-top: 1rem;
  border-radius: 5px;
}

.support-table-data {
  display: flex;
  justify-content: space-between;
  background-color: #1b2430;
  padding: 1rem 1rem;
}

.support-count {
  width: 8%;
}

.support-message {
  width: 57%;
}

.support-status {
  width: 9%;
}

.support-apply-date {
  width: 13%;
}

.support-receive-date {
  width: 13%;
}

.payment_history-table tr td {
  padding: 5px !important;
}

.pageActive {
  background-color: #000;
}

.client_navbar {
  position: absolute !important;
  top: 0;
  width: 100%;
  right: 0rem;
  background-color: #1b2430 !important;
}

.client_profile .navbar-nav .nav-link {
  color: #3eff00 !important;
}

.paymentMethod {
  font-size: 3rem;
  text-align: center;
  color: #ff2323;
  cursor: pointer;
  text-transform: capitalize;
}

.client_footer {
  display: flex;
  flex-direction: row;
  padding: 2.5rem;
  column-gap: 2%;
}

@media screen and (max-width: 580px) {
  .client_wraper {
    position: fixed;
  }

  .client_profile {
    width: 100%;
  }

  .customer_information_table {
    display: flex;
    flex-direction: column;
  }

  .client_dashboard_section {
    padding: 5rem 1rem;
  }

  .services {
    padding: 1rem !important;
  }

  .restForm-wraper {
    width: 95%;
    margin: 0 auto;
  }

  .up_downLoad {
    flex-direction: column;
  }

  .up_down {
    margin-bottom: 1rem;
    width: 100%;
  }

  .payment_history_list {
    flex-direction: column;
  }

  .support-ticket-table {
    margin: auto;
    width: 100%;
  }

  .support-message-form {
    margin: auto;
    width: 100%;
    margin-bottom: 1rem;
  }

  .support-table-data {
    padding: 0.5rem 0.5rem;
  }

  .support-table-data {
    font-size: 16px;
  }

  .support-count {
    width: 4%;
  }

  .support-message {
    width: 37%;
  }

  .support-status {
    width: 18%;
    margin-right: 0px;
  }

  .support-apply-date {
    width: 22%;
  }

  .support-receive-date {
    width: 20%;
  }

  .support-table-data {
    justify-content: space-evenly;
  }

  .welcome_title {
    font-size: 1.5rem;
  }

  .client_footer {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    row-gap: 1rem;
  }

  .packages_info_wraper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
  }
}
