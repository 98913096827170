.customBtn {
  width: 120px;
  text-align: center;
}
.customBtn > img {
  width: 22px;
}
.deletingAction {
  padding: 20px 5px;
  color: tomato;
}

.goToSubArea {
  font-size: 15px;
  text-align: left;
}
.gotoSubAreaBtn {
  /* background: linear-gradient(45deg, #9449f5, #681ec9); */
  background: #04a753;
  color: white !important;
  padding: 3px 12px;
  font-size: 13px;
  font-weight: 400;
  border-radius: 4px;
}
.gotoSubAreaBtn:hover {
  background: #048b46;
}

.header_icon {
  width: 50px;
  height: 38px;
  color: var(--primaryColor);
  background: white;
  border-radius: 7px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s;
  font-size: 19px;
}

.header_icon:hover {
  background-color: #04a753 !important;
  color: #fcf2f2 !important;
}
