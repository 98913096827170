.home {
  padding: 10px;
  border-radius: 10px;
  width: 100%;
}
.dashboardTitle {
  font-size: 24px;
  padding-top: 10px;
  padding-bottom: 5px;
  font-weight: 600;
}
.dataCard {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0px;
  border-radius: 5px;
  box-shadow: 2px 2px 3px rgb(0 0 0 / 11%);
  color: rgb(228, 228, 228);
  margin-bottom: 10px;
  position: relative;
  height: 10rem;
}
.ThreeDots {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 7px;
  margin-right: 5px;
  font-size: 18px;
  display: none;
}
.cardIcon {
  font-size: 32px;
  width: 50px;
  height: 50px;
  background: rgb(255 255 255 / 18%);
  text-align: center;
  border-radius: 41%;
  margin-right: 10px;
}
.chartSection {
  padding-top: 20px;
  padding-left: 2px;
}
.chartSection > p {
  font-size: 16px;
  font-weight: 600;
  padding-right: 5px;
}
.chartSection > h2 {
  font-size: 22px;
  color: rgb(239 239 239);
}
.ChartsHeadernew {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 20px;
}
.ChartsFilternew {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
}
.dashboardButton {
  border-radius: 9px;
  font-size: 22px;
  color: green;
  margin: 2px 10px;
  cursor: pointer;
}
.dashboardLoader {
  font-size: 22px;
  margin: 2px 10px;
}
.chartFilteritem {
  margin-right: 10px !important;
}
.selectGraph {
  width: 200px;
  margin: 0 25px;
}
.selectGraph > label {
  color: #1b975e;
}
#card1 {
  background: linear-gradient(17deg, #364f6b, #5083bd);
}
#card2 {
  background: linear-gradient(17deg, #8551a4, #2dd4a7);
}

#card3 {
  background: linear-gradient(17deg, #097cf1, #4596e7);
}

#card4 {
  background: linear-gradient(17deg, #314592, #606c38);
}

#card5 {
  background: linear-gradient(17deg, #454545, #588157);
}

#card6 {
  background: linear-gradient(17deg, #2285bb, #003049);
}
#card7 {
  background: linear-gradient(17deg, #454545, #84a59d);
}
#card8 {
  background: linear-gradient(17deg, #454545, #a98467);
}
#card9 {
  background: linear-gradient(17deg, #454545, #34ccd1);
}
#card10 {
  background: linear-gradient(17deg, #454545, #4a4e69);
}
#card11 {
  background: linear-gradient(17deg, #454545, #669bbc);
}
#card12 {
  background: linear-gradient(17deg, #454545, #34ccd1);
}
#card13 {
  background: linear-gradient(17deg, #9f2362, #0e4c72);
}
#card14 {
  background: linear-gradient(17deg, #784860, #3988b8);
}

.chartTitle {
  padding: 15px;
  padding-bottom: 0;
  font-size: 20px;
  font-weight: 500;
}
.lineChart {
  /* border: 1px solid rgb(0 0 0 / 7%); */
  padding: 20px 10px;
  margin: 20px 0;
  padding-top: 0;
}
.table {
  --bs-table-striped-color: inherit !important;
  color: inherit !important;
}

/* tabel collector section */
.tableWrapper {
  padding: 20px;
}

.accordion-button:focus {
  box-shadow: none !important;
}

@media only screen and (max-width: 778px) {
  .ChartsFilternew {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
  .chartFilteritem {
    width: 200px !important;
  }
  .selectGraph {
    display: flex;
    flex-direction: column;
  }
}
@media only screen and (max-width: 700px) {
  .chartFilteritem {
    width: 100% !important;
  }
}
@media only screen and (max-width: 300px) {
  .ChartsHeadernew {
    flex-direction: column;
    width: 100%;
  }
}
