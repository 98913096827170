.messageGuide > p {
  font-size: 18px;
}
.oneElementInput {
  display: flex;
  padding: 10 0px;
}
.marginRight {
  transform: scale(1.2);
  margin-right: 10px;
}

/* css added by enamul */
.uiChange {
  display: flex;
  flex-direction: column;
}

.smsPerchase {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 20px;
  border-radius: 7px;
  border: 1px solid #ddd;
}
.smsbuy {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 20px;
}
.smsbal {
  /* background-color:rgb(192, 190, 190); */
  border-radius: 4px;
  height: 45px;
  font-weight: bold;
  font-size: 17px;
  color: rgb(2, 2, 2);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.smsbutton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}
.smsparchasebtn {
  width: 100%;
  margin: 10px;
  border: none;
  border-radius: 5px;
  height: 40px;
  font-weight: bold;
}

.button1 {
  color: white;
  background-color: #086e7d;
}

.button2 {
  background-color: #ff1700;
}

.numsms {
  font-weight: bold;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.smsinput {
  flex: 2;
  border: 2px rgb(136, 136, 136) solid;
  border-radius: 4px;
  padding-left: 5px;
  font-weight: 500;
  height: 35px;
}

.smsinput:focus {
  outline: none;
}
.smsspan {
  flex: 1;
  font-size: 17px;
}
.amountsms {
  font-weight: bold;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
  font-size: 17px;
}
.price {
  color: green;
  font-weight: bolder;
}

.buysms {
  border: none;
  border-radius: 5px;
  width: 150px;
  height: 40px;
  background-color: #f0a500;
  font-weight: 600;
}

.kroymullo {
  margin-right: 10px;
}

.cusSelect {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.refreshDiv {
  display: flex;
  align-items: center;
  height: 45px;
  width: fit-content;
  justify-content: space-between;
  border-radius: 4px;
}

.refreshIcon {
  height: 45.3px;
  width: 50px;
  background-color: #dbe6d4;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  font-size: 20px;
  font-weight: bold;
}
.refreshIcon:hover {
  background-color: #c5d3bd;
}

.balancetext {
  background-color: #e2ecec;
  height: inherit;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mainsmsbalance {
  margin-left: 10px;
  margin-right: 10px;
}

.displayFlexx {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-direction: column;
  flex: 1;
}

.templatelabel {
  margin-left: 8px;
  font-weight: normal;
}
.radioselect {
  margin-top: 14px;
  display: flex;
  align-items: center;
}
.radioselect > input {
  width: 22px;
  height: 22px;
  cursor: pointer;
}

.message_radio {
  gap: 5px;
  display: flex;
  align-items: center;
}
.message_radio > input {
  width: 17px;
  height: 17px;
}
.message_radio > label {
  color: rgb(131, 131, 131);
  cursor: pointer;
}

.day_checkbox {
  gap: 5px;
  display: flex;
  align-items: center;
}
.day_checkbox > input {
  width: 17px;
  height: 17px;
}

.billconfirm {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  flex-direction: column-reverse;
}
.showthesequence {
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: start;
  font-weight: normal;
  font-size: 14px;
  border: 2px rgb(34, 178, 189) solid;
  height: 200px;
  width: 100%;
  margin-top: 20px;

  border-radius: 5px;
  flex-direction: column;
  padding: 5px;
  overflow: scroll;
}
.endingtext {
  text-align: start;
}

.onoff {
  border-radius: 0%;
}
.smsCount {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
  font-weight: bold;
}
.smsLength {
  margin-right: 10px;
}
.settingMainDiv {
  /* display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;
  width: 100%; */
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); */
  grid-gap: 50px;
  margin-top: 10px;
  padding: 20px;
  border-radius: 7px;
  border: 1px solid #ddd;
}

.textButton {
  width: max-content;
  height: 2.1rem;
  font-size: 1rem;
  font-weight: 600;
  padding: 0.6rem;
  color: var(--primaryColor);
  background: white;
  border-radius: 0.2rem;
  margin-left: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  cursor: pointer;
}

.textButton:hover {
  background: #0ea158;
  color: white;
}

.text_icons {
  font-size: 1.5rem;
  margin-right: 0.2rem;
}

.radioSelect {
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
}
.radioSelect > input {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.templateLabel {
  margin-left: 5px;
  font-weight: 700 bold;
  color: rgb(104, 104, 104);
}

.checkboxSelect {
  display: flex;
  align-items: center;
}
.checkboxSelect > input {
  width: 22px;
  height: 22px;
}
.checkboxSelect > label {
  color: rgb(131, 131, 131);
  cursor: pointer;
}
