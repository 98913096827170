.bananoAlert {
  padding: 10px;
  margin: 10px 0;
  background: rgb(124 255 124 / 22%);
  font-weight: 500;
  color: #00bb00;
  border-radius: 5px;
  text-align: center;
  font-size: 18px;
}
.LoaderTD {
  text-align: center;
  padding: 30px 0px !important;
}
.changeLabelFontColor {
  color: rgb(100, 100, 100);
  /* color: #2d86de; */
}

.inputLabelFontColor {
  /* color: rgb(100, 100, 100); */
  color: #2d86de;
}
