.customcontainer {
  width: 600px;
  margin: 0 auto;
  text-align: center;
  padding-top: 200px;
}
.successTitle3 {
  color: rgb(255, 0, 0);
}
.successText {
  color: gray;
  font-size: 19px;
  margin-bottom: 20px;
}
.successButton {
  text-decoration: none;
  background: #4caf50;
  padding: 5px 20px;
  border-radius: 9px;
  color: white;
  font-weight: 500;
}
.successButton:hover {
  background: #51cc55;
  color: white;
}
