.register {
  width: 100%;
  height: 100vh;
  position: absolute;
}
.Loader {
  width: 100%;
  height: 100vh;
  /* background: url("../../../assets/img/dashboard.gif") center no-repeat; */
  background-size: 60px;
  background-color: rgb(221 221 221 / 36%);
  backdrop-filter: blur(3px);
  z-index: 100;
  display: none;
}
.form-control {
  background: none !important;
  color: #939393 !important;
}
.form-control:focus {
  color: #939393 !important;
}

.customInputGroup {
  /* background: rgb(228 3 255 / 9%); */
  flex-direction: column;
  display: flex;
  padding: 17px;
  margin: 20px 0;
  border-radius: 7px;
  border: 1px solid rgb(221 221 221 / 62%);
}

.errorMessage {
  color: rgb(255, 94, 0);
  margin-top: 2px;
  font-size: 16px;
}

.formStyle {
  max-width: 600px;
  padding: 50px;
  margin: 0 auto;
  /* border: 1px solid #dddddd52; */
  border-radius: 20px;
  margin-top: 40px;
}
.customFields {
  margin-top: 15px;
}

#get_customer {
  margin-top: 15px;
  display: none;
}

/* .customFormSelect::selection{
  border: none;
   
} */
.customOption {
  margin-top: 50px;
}
.submitBtn {
  padding: 7px 40px;
  background: var(--primaryColor);
  border-radius: 7px;
  border: none;
  font-weight: 600;
  color: white;
}
.registerCacleBtn {
  margin-left: 10px;
  border-radius: 7px !important;
}
.radiopak {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.singlePak {
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: bold;
  font-size: 20px;
  margin-top: 10px;
}
.pakinput {
  margin-right: 8px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  border-radius: 10px;
}
.pakinfo {
  width: 100%;
  /* height: 40px; */
  padding: 10px;
  border: 2px gainsboro solid;
  border-radius: 5px;
  display: block;
  margin-bottom: 7px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  background: var(--primaryColor);
  color: white;
}
.pakinfo > span {
  font-weight: bold;
  margin-left: 5px;
}

/* .mainfee{
  
} */

.mainfeecross {
  text-decoration: line-through;
  margin-right: 5px;
  text-decoration-thickness: 1px;
  text-decoration-color: red;
  font-size: 17px;
}

.strikethrough {
  position: relative;

  margin-right: 5px;

  font-size: 17px;
}
.strikethrough:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  color: red;
  right: 0;
  border-top: 1px solid;
  border-color: inherit;

  -webkit-transform: rotate(+7deg);
  -moz-transform: rotate(+7deg);
  -ms-transform: rotate(+7deg);
  -o-transform: rotate(+7deg);
  transform: rotate(+7deg);
}
.discount {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 2px solid  purple; */
  color: whitesmoke;
  background-color: crimson;
  border-radius: 5px;
}

.disStrong {
  font-weight: bold;
  font-size: 30px;
  margin-right: 20px;
}

.disspan {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
}

.spinner-square {
  display: flex;
  flex-direction: row;
  width: 90px;
  height: 120px;
}

.spinner-square > .square {
  width: 5px;
  height: 30px;
  margin: 2px;
  border-radius: 4px;
}

.square-1 {
  animation: square-anim 1200ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 0s
    infinite;
}

.square-2 {
  animation: square-anim 1200ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 200ms
    infinite;
}

.square-3 {
  animation: square-anim 1200ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 400ms
    infinite;
}

@keyframes square-anim {
  0% {
    height: 30px;
    background-color: rgb(111, 163, 240);
  }
  20% {
    height: 30px;
  }
  40% {
    height: 50px;
    background-color: rgb(111, 200, 240);
  }
  80% {
    height: 30px;
  }
  100% {
    height: 30px;
    background-color: rgb(111, 163, 240);
  }
}
