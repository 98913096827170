.mikrotikConfigure {
  font-size: 15px;
  text-align: center;
}
.mikrotikConfigureButtom {
  /* background: linear-gradient(45deg, #9449f5, #681ec9); */
  background: #04a753;
  color: white !important;
  padding: 3px 12px;
  font-size: 13px;
  font-weight: 400;
  border-radius: 4px;
}
.mikrotikConfigureButtom:hover {
  background: #048b46;
}
