.header {
  width: 100%;
  height: 60px;
  border-bottom: 1px solid rgb(126 126 126 / 10%);
  box-shadow: 0px 0px 10px rgb(0 0 0 / 23%);
  border-bottom: 1px solid rgb(0 0 0 / 42%);
  position: fixed;
  z-index: 5;
}
.iRgSuG,
.cyVYUU {
  color: #9449f5 !important;
}
.headerBar {
  display: flex;
  align-items: center;
}

.activity {
  font-size: 22px;
  cursor: pointer;
  color: white;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--primaryColor) !important;
}

.logoSide {
  flex: 2 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.logo_section {
  position: absolute;
  left: 270px;
}

@media screen and (max-width: 1024px) {
  .logo_section {
    position: absolute;
    left: 67px;
    top: 15px;
  }
}

.logoSide > h2 {
  color: var(--primaryColor) !important;
}
.headerLinks {
  height: 60px;
  flex: 6;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 50px;
}
.darkLight {
  height: 30px;
  width: 30px;
  cursor: pointer;
  background: rgb(223 145 255 / 12%);
  border-radius: 50%;
  margin-top: 0px;
  margin-right: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primaryColor);
}

.headerIcon {
  width: 2rem;
  height: 2.1rem;
  color: var(--primaryColor);
  background: rgb(223 145 255 / 12%);
  border-radius: 4%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.openSupportTicket {
  background: rgb(223 145 255 / 12%);
  border-radius: 4%;
  padding: 5px 10px;
  margin: 0px 10px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 40%;
  position: fixed;
}

.openSupportTicket > a {
  color: var(--primaryColor) !important;
  text-decoration: none;
}

.messageBalance {
  position: relative;
  display: inline-block;
}

.messageBalance > span {
  position: absolute;
  /* background-color: #008000; */
  color: #fff;
  top: -4px;
  left: 8px;
  display: inline-block;
  padding: 0 4px;
  min-width: 8px;
  max-width: 33px;
  height: 16px;
  border-radius: 22px;
  text-align: center;
  font-family: "Roboto", "Arial", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.goToLoginPage {
  font-size: 16px;
  font-weight: 400;
  border: 1px solid;
  padding: 5px 30px;
  border-radius: 19px;
  cursor: pointer;
  color: var(--primaryColor);
  font-weight: 600;
}
.goToLoginPage:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.247);
}
.dropdown-menu {
  background: var(--primaryColor) !important;
}
.dropdown-item {
  color: #ffffff !important;
}
.dropdown-item:hover {
  background: #297fd4 !important;
}
.profileDropdownBtn {
  border: none;
  background: none;
  color: var(--primaryColor);
  font-weight: 600;
}
.profileDropdownImg {
  width: 35px;
  height: 35px;
  object-fit: cover;
  border-radius: 30%;
  margin: 0px 10px;
}
.profileList {
  /* border-bottom: 1px solid; */
  color: rgb(230, 227, 227);
  padding: 5px 0;
}
.dropdownIcon {
  margin-right: 10px;
}
.dropdown-menu.show {
  box-shadow: 4px 20px 15px rgb(0 0 0 / 10%);
}

.logOutLi {
  border-bottom: none;
}

.logOutTxt {
  color: rgb(251 173 143) !important;
}

.homediv {
  width: 200px;
  height: 40px;
  height: inherit;
  padding: 5px;
}
.newLogoo {
  height: 40px;
  object-fit: contain;
  padding: 3px;
  margin-left: 10px;
}
.homeicon {
  width: 33px;
  height: 33px;
}

.homeicon:hover {
  color: #2666cf;
}

.supportDetails {
  font-size: 18px;
  font-weight: 700;
  color: #1d9ee3;
  font-family: sans-serif;
  margin-left: 5rem;
  margin-right: 10px;
}

@media only screen and (max-width: 720px) {
  .headerLinks {
    margin-right: 20px;
  }
}

@media only screen and (max-width: 915px) {
  .phone_view_none {
    display: none !important;
  }
}
