.landingWrapper {
  width: 100%;
  min-height: 100vh;
  text-align: center;
  position: relative;

  background-color: #ffffff;
  /* url(https://images.hdqwalls.com/wallpapers/formation-abstract-colors-4k-1e.jpg); */

  /* background-size: cover; */
  text-align: center;
}

.landingWrapper:after {
  /* content: ""; */
  /* position: absolute; */
  /* top: 0;
  left: 0; */
  width: 100%;
  height: 100%;
  /* background: linear-gradient(45deg, #009688, #ff68f2); */
  pointer-events: none;
}

.textBox {
  position: absolute;
  width: 40%;
  height: 100%;
  padding: 100px;
  display: flex;
  -webkit-backdrop-filter: blur(80px);
  backdrop-filter: blur(10px);
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 0% 100%);
  clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 0% 100%);
  justify-content: center;
  background: #ffffff;
  align-items: center;
  flex-direction: column;
  color: #f5f5f5;
}

.textBox2 {
  position: absolute;
  width: 60%;
  right: 0px;

  height: 100%;
  /* padding-top: 80px; */
  padding: 20px;
  display: flex;
  /* -webkit-backdrop-filter: blur(80px); */
  /* backdrop-filter: blur(10px); */
  overflow-y: scroll;
  text-align: start;

  flex-direction: column;
  color: #525e75;
}

.intro1 {
  width: 100%;

  padding: 10px;
  font-weight: 600;
}

.landingMain {
  display: flex;
  align-items: center;
  /* justify-content:space-between; */
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.LandingTitle {
  font-size: 5rem;
}

.landingText {
  font-size: 1.3rem;
  margin: 30px 85px;
  color: #bfbfbf;
}

.navivationBar {
  padding: 10px 0px;
}

.landingSubscribeBtn {
  padding: 10px 42px !important;
  font-size: 18px;
  font-weight: 500;
}

.gotoLogin {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.custom-btn {
  background-color: #297fd4;
  color: whitesmoke;

  font-weight: bolder;
  height: 50px;
  width: 240px;
  margin-left: 10px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: none;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 100px;
  font-weight: bold;
  flex-direction: column;
}

.custom-btn:hover {
  box-shadow: 0px 0px 10px rgb(59, 55, 55);
}

.landingFooter {
  width: 100%;
  padding: 10px 0;
}

.bulletul {
  list-style-type: square;
}

.bulletul2 {
  list-style-type: circle;
}

.landinglogodiv {
  height: 90px;
  width: fit-content;
}

.landingLogonew {
  height: 100px;
  /* width: 300px; */
  object-fit: cover;
}

.sslCommerz {
}

.textnew {
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
}

.alllinks {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.navnew {
  margin: 0 10px;
}

.newLink:hover {
  text-decoration: underline;
  color: goldenrod;
}

.sslimg {
  width: 100%;
  object-fit: contain;
  height: fit-content;
}

@media screen and (max-width: 900px) {
  .alllinks {
    flex-wrap: wrap;
  }

  .navnew {
    margin: 15px;
  }

  .landingWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    min-height: 0px;
    margin: 0;
  }

  .landingContainer {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    position: inherit;
  }

  .landingMain {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .textBox {
    margin: 0;
    position: inherit;
    width: inherit;
    flex: 1;
    height: 100vh;
    display: flex;
    -webkit-backdrop-filter: none;
    backdrop-filter: none;
    -webkit-clip-path: none;
    clip-path: none;
    padding: 20px;
    justify-content: center;
    background: #ffd124;
    align-items: center;
    flex-direction: column;
    color: #f5f5f5;
  }

  .buttons {
    margin-top: 20px;
  }

  .landinglogodiv {
    margin-bottom: 100px;
    margin-left: 20px;
  }

  .textBox2 {
    width: 100%;
    flex: 1;
    height: 100vh;
    height: max-content;
    /* padding-top: 80px; */
    padding: 10px;
    overflow-y: hidden;
    display: flex;

    text-align: start;

    flex-direction: column;
    color: #525e75;
  }
}

.textboxwithUser {
  padding: 20px;
}

@media screen and (max-width: 937px) {
  .alllinks {
    flex-wrap: wrap;
  }

  .navnew {
    margin: 10px;
  }
}

@media screen and (max-width: 500px) {
  .landingLogonew {
    object-fit: contain;
    width: 70%;
  }

  .sslimg {
    object-fit: cover;
    overflow: hidden;
  }
}

.ispOwner {
  background: linear-gradient(90deg, #00d2ff 0%, #3a47d5 100%);
  height: 100vh;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* table.client_info {
  color: #bfbfbf;
  font-weight: 700;
  margin-top: 2rem;
} */

table.client_info td {
  padding: 0px 10px !important;
  color: #2b2828 !important;
  font-size: 1.1rem !important;
}

.selectInfo {
  width: 15% !important;
  border: none;
}

.customer {
  /* border: 1px solid black; */
  /* text-align: center; */
  color: #ffffff;
}

.customerInfo {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.customerInfo {
  margin-top: 10rem !important;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.customer-title {
  font-size: 2.5rem;
  color: #ffffff;
  border-bottom: 1px solid white;
  width: 100%;
  text-align: center;
  padding: 1rem;
}

table.customer-info td {
  padding: 0px 10px !important;
  color: #bfbfbf !important;
  font-size: 1.5rem !important;
  line-height: 3rem;
}
