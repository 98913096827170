.customerFormCheck {
  padding: 0;
  /* margin-left: -22px !important; */
}
.spetialSortingRow > th {
  cursor: pointer;
}
.arrowDownUp {
  padding: 1px;
  margin-left: 4px;
  color: #12cf6f;
}
.deletingAction {
  padding: 20px 5px;
  color: tomato;
}
.customerFormCheck > p {
  font-weight: 500;
  color: black;
  margin-bottom: 7px;
}
.customerAction {
  display: flex;
  align-items: center;
  padding-left: 16px;
}
.dropdown-menu > li {
  cursor: pointer;
  display: block;
}

.thSt {
  color: #2485aa;
  font-size: 17px;
}
.comstomerFieldsTitle {
  padding: 0;
  margin: 0;
}
.customerGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}
.ifCannotSelectBill {
  display: none;
  text-align: center;
  color: rgb(250, 79, 0);
  padding: 0px;
}
.mikrotikSection {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}
.areaSection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  padding-bottom: 15px;
}
.areaSection > div > p {
  color: black;
  padding: 0;
  margin: 0;
}
.AllAreaClass {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-gap: 20px;
  margin-top: 10px;
  padding: 20px;
  border-radius: 7px;
  border: 1px solid #ddd;
  /* overflow-x: scroll; */
}
.areaParent {
  font-size: 16px;
  color: black;
  font-weight: 600;
}
.displayFlex {
  display: flex;
  align-items: center;
  padding: 6px 0;
}
.displayFlex :nth-child(2) {
  padding-left: 7px;
}
.autoDisable {
  background: #efefef;
  width: fit-content;
  height: 38px;
  margin-top: 24px;
  padding: 5px 15px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}
.paginationSection {
  display: flex;
  justify-content: space-between;
}
.paginationFormSelect {
  width: 130px !important;
  height: 38px;
}
.pppoeSection2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

/* css added by enamul */
.allCollector {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.allCollectorSpan {
  margin: 10px;
}

.form-selectt {
  width: 150px;
  height: 40px;
  max-width: 200px;
  cursor: pointer;
  padding: 6px;

  margin: 10px;
}

.submitdiv {
  margin: 18px 0;
}

/* css added by enamul end  */

@media only screen and (max-width: 980px) {
  .customerGrid {
    display: initial;
  }
}
@media only screen and (max-width: 700px) {
  .form-select {
    max-width: 100% !important;

    margin-top: 10px !important;
  }
  .dateDiv {
    width: inherit !important;
  }
}
