.displayGridForDiposit {
  display: grid;
  grid-template-columns: 1fr 1fr 150px;
  grid-gap: 10px;
  padding: 20px;
  /* align-items: center; */
}

.acceptBackground {
  background: #29b32d;
  color: white;
  text-align: center;
  padding: 2px 16px;
  font-size: 13px;
  border-radius: 18px;
}
.AcceptRejectBtn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: -54px;
}
.loaderDiv {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 18px;
}
.AcceptRejectBtn > button {
  border: none;
  padding: 4px 20px;
  color: white;
  font-size: 14px;
  border-radius: 15px;
  text-transform: uppercase;
  width: 90px;
}

.textAlignCenter {
  text-align: center;
}
.AcceptRejectBtn > button:hover {
  opacity: 0.9;
}
.AcceptRejectBtn :nth-child(1) {
  background: rgb(20, 124, 223);
  margin-right: 10px;
}
.AcceptRejectBtn :nth-child(2) {
  background: rgb(248, 93, 21);
}
.DipositStatusSection {
  padding: 20px;
}
.dipositStatusCheck {
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  padding: 20px;
}
.ACPbtn {
  width: 100px;
  margin: 0 auto;
  text-align: center;
  font-size: 13px;
  color: white !important;
  padding: 6px;
  border-radius: 8px;
  opacity: 0.7;
}
.statusClass {
  color: #29b32d;
}
.rejectClass {
  color: #d63200;
}
.acceptBtn {
  background: #29b32d;
}
.rejectBtn {
  background: #ed3826;
}
.pendingBtn {
  background: #8f5ee9;
}

@media screen and (max-width: 771px) {
  .AcceptRejectBtn {
    margin-left: -50px;
    flex-wrap: wrap;

    justify-content: center;
  }
  .AcceptRejectBtn :nth-child(1) {
    margin-left: 10px;
  }
  .AcceptRejectBtn :nth-child(2) {
    background: rgb(248, 93, 21);
    margin-top: 10px;
  }
}

.accordionBorder {
  border: none !important;
}
.accordionPadding {
  padding: 0% !important;
  margin-top: 10px !important;
}

.displayGrid4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
}
