a {
  text-decoration: none !important;
  color: initial !important;
}
.sidebar {
  width: 250px;
  height: 100vh;
  position: fixed;
  /* background: linear-gradient(90deg, #9449f5, rgb(148 73 245 / 0%)); */
  padding: 10px 0;
  z-index: 100;
  overflow: hidden;
}

/* .sidebar:hover {
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: white;
}
::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
} */

.sidebar {
  overflow: scroll;
  overflow-x: hidden;
}

/* ::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
/* background: transparent;  */
/* Optional: just make scrollbar invisible */
/* } */
/* Optional: show position indicator in red */
/* ::-webkit-scrollbar-thumb {
  background: #ff0000;
} */
#active {
  box-shadow: 6px 0px 0px #297fd4 inset;
  /* filter: contrast(5); */
  color: #297fd4;
}
.removeSidebar {
  display: none;
}
.menuIcon {
  display: none;
  width: 100px !important;
  position: relative;
  filter: invert(1);
  z-index: 10;
  font-size: 22px;
  padding: 13px;
  margin-left: 0px;
}
.adminDashboardTitle {
  font-size: 20px;
  font-weight: bold;
  /* border-bottom: 1px dotted; */
  /* padding: 20px 0 15px 40px; */
  color: var(--primaryColor) !important;
  cursor: pointer;
}
.GotoHomeFromDashboard {
  font-size: 20px;
  margin: -4px 10px 0px -3px;
}

.role-section {
  font-family: Roboto;
  color: #3e3232b5;
  font-size: 15px;
  font-weight: 500;
}
.sidebarLinksName {
  padding-top: 5px;
}
.sidebarUl {
  padding: 0px;
  padding-top: 10px;
}
.sidebarItems {
  list-style: none;
  display: flex;
  align-items: center;
  padding: 10px 38px;
  font-weight: 600;
  cursor: pointer;
  transition: 0.3s;
}

.sidebarItems:hover {
  filter: contrast(5) !important;
  background-color: #1276a705;
}
.sidebarIcon {
  font-size: 18px;
  margin-right: 15px;
  margin-top: -1px;
  /* color: var(---primaryColor) !important; */
  color: #328eea;
}
.arrowRightFillStyle {
  padding-left: 20%;
}
.billingSidebarItems {
  font-size: 14px;
  margin-bottom: 0px;
  padding: 5px 38px;
}
.hideSubBilling {
  display: block !important;
}
#toggleSubBilling {
  display: none;
  background: rgb(148 73 245 / 12%);
  padding: 7px 0;
}

.companyName {
  font-family: "Roboto";
  /* font-size: medium; */
  text-align: center;

  font-weight: bold;
  /* color: gray; */
  transition: all 2s ease-in-out;
}

.sideBar_accordian .accordion-item {
  border: none;
  background-color: transparent;
}

.sideBar_accordian .accordion-item .accordion-button {
  background-color: transparent;
  box-shadow: none;
  color: #496356;
  font-weight: 600;
}

.sideBar_accordian h2.accordion-header {
  margin-left: 1.1rem;
}

/* .sideBar_accordian h2.accordion-header button span {
  color: #acb9ac !important;
} */

.sideBar_accordian .accordion-body {
  padding: 0;
  margin-left: 0.5rem;
}

@media screen and (max-width: 1024px) {
  .sidebar {
    display: none;
    z-index: 100;
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.664);
  }
  .toggleSidebar {
    display: block;
  }
  .adminDashboardTitle {
    padding-left: 30px;
  }
  .HideSidebar {
    width: calc(100vw - 249px);
    /* height: 100vh; */
    background: rgba(0, 0, 0, 0.274);
    position: absolute;
    top: 0;
    margin-left: 45px;
  }
  .menuIcon {
    width: 100px !important;
    display: block;
    position: fixed;
  }
  .ListIcon {
    cursor: pointer;
    position: absolute;
    z-index: 1000;
    margin-top: 6px;
    margin-left: 20px;
  }
}
