.profileWrapper {
  width: 80%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
  margin: 0 auto;
  padding: 50px 0;
}
@media only screen and (max-width: 980px) {
  .profileWrapper {
    display: initial;
  }
  .marginTop20 {
    margin-top: 30px;
  }
}
