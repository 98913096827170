.home {
  padding: 10px;
  border-radius: 10px;
}
.dashboardTitle {
  font-size: 30px;
  padding-top: 10px;
  padding-bottom: 5px;
  font-weight: 600;
}
.dataCard {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0px;
  border-radius: 5px;
  box-shadow: 2px 2px 3px rgb(0 0 0 / 11%);
  color: rgb(228, 228, 228);
  margin-bottom: 10px;
  position: relative;
}
.ThreeDots {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 7px;
  margin-right: 5px;
  font-size: 18px;
  display: none;
}
.cardIcon {
  font-size: 32px;
  width: 50px;
  height: 50px;
  background: rgb(255 255 255 / 18%);
  text-align: center;
  border-radius: 41%;
  margin-right: 10px;
}
.chartSection {
  padding-top: 10px;
  padding-left: 2px;
}
.chartSection > p {
  font-size: 16px;
  font-weight: 600;
  padding-right: 5px;
}
.chartSection > h2 {
  font-size: 22px;
  color: rgb(239 239 239);
}
.ChartsHeader {
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding: 10px;
}
.ChartsFilter {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  padding: 10px;
}
.selectGraph {
  width: 200px;
  margin: 0 25px;
}
.selectGraph > label {
  color: #1b975e;
}
#card1 {
  background: linear-gradient(17deg, #364f6b, #5083bd);
}
#card2 {
  background: linear-gradient(17deg, #9d5c0d, #d4892d);
}

#card3 {
  background: linear-gradient(17deg, #097cf1, #4596e7);
}

#card4 {
  background: linear-gradient(17deg, #11999e, #006d77);
}

.chartTitle {
  padding: 15px;
  padding-bottom: 0;
  font-size: 20px;
  font-weight: 500;
}
.lineChart {
  /* border: 1px solid rgb(0 0 0 / 7%); */
  padding: 20px 10px;
  margin: 20px 0;
  padding-top: 0;
}
.table {
  --bs-table-striped-color: inherit !important;
  color: inherit !important;
}

.tableWrapper {
  padding: 20px;
}
.paymentGateway {
  display: flex;
  flex-direction: row;
  justify-content: start;
  margin: 30px 0;
}

.invoiceAction {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: end;
  grid-gap: 10px;
}

.displayGrid2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}

.displayGrid5 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
}
